import React, { useEffect, useState } from "react";
import DealTypes from "./DealTypes.js";
import ProductOffers from "./ProductOffers.js";
import MoreDeals from "./MoreDeals.js";
import ProductDetails from "./ProductDetails.js";
import DayDeals from "./DayDeals.js";
import { Link } from "react-router-dom";
import Dealday from '../../Home/Dealday.js';
import { getSimilarDeals, clearAction } from "../../../Reducer/SimilardealsSlice.js";
import { useDispatch, useSelector } from "react-redux";
import ProductComments from "./Comments.js";
import { useNavigate } from "react-router-dom";
import LoadingRedirect from "../../Tools/LoadingRedirect/index.js";
import DealHistory from "./DealHistory.js";
export default function ProductInfo({product, onReplyClick}){
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); 
  const [redirectLink, setRedirectLink] = useState(null);
  const handleBuyNowClick = () => {
    navigate('/loading', { state: { link: product.LINK } });
  };
  const dispatch = useDispatch();
  const similarproductData = useSelector((state)=> state.similardeals.content);
  const similarproductsuccess = useSelector((state)=> state.similardeals.success);
  const [loading, setLoading] = useState(false);
  const [similarproducts, setSimilarProducts] = useState([]);
  useEffect(()=>{
    if(product.STOREID){
      const formData = {
        store_id: product.STOREID,
      };
      dispatch(getSimilarDeals(formData));
    }
  },[product])

  useEffect(()=>{
    if(similarproductData && similarproductsuccess){
      setSimilarProducts(similarproductData.slice(0,4));
      dispatch(clearAction());
      setLoading(false);
    }
  },[similarproductData]);
    return (
      <>
        <h6 class="tophd">
          <Link to="/">Home</Link>
          &lt; {product.TITLE}
        </h6>
        <div class="row">
          <div class="col-sm-12">
            <DealTypes></DealTypes>
          </div>
          <div class="col-sm-5 col-xl-4">
            <div class="image">
              <img
                src={product.THUMBIMAGE}
                alt="img"
              />
            </div>
          </div>
          <div class="col-sm-7 col-xl-8">
            <h4>
              {/* <a href={product.COUPON.LINK} target="_blank">[Apply Coupon]</a>{" "} */}
              {product.TITLE}
            </h4>
            <label>
              <a>
                <i class="fa-regular fa-message"></i> {product.TOTALCOMMENT}
              </a>
              <a>
                <i class="fa-regular fa-heart"></i> {product.TOTALLIKE}
              </a>
            </label>
            <div class="d-flex gap-3 flex-button-al">
            <h3>
            {!isNaN(Number(product.DISCOUNT_PRICE)) && product.DISCOUNT_PRICE !== '' ? (
                <>
                  Rs. {product.DISCOUNT_PRICE}{" "}
                </>
              ) : (
                <>
                  {product.DISCOUNT_PRICE}
                </>
              )}
              <span>
              {product.ORIGNAL_PRICE !== ''&& (
                <>
                Rs.
                {product.ORIGNAL_PRICE}
                </>
              )}
              </span>
              {product.DISCOUNT_PERCENTAGE !== 0 && (
                <>
              <span class="per">({product.DISCOUNT_PERCENTAGE}%Off)</span>
                </>
              )}
              {product.STOREIMAGE && (
                <>
              <img src={product.STOREIMAGE} alt="image"/>
                </>
              )}
   

            </h3>
            <div class="button-code-al">
            {product.COUPON && product.COUPON.CODE ? 
          <>

                    <span class="coupon-code-al">{product.COUPON.CODE}

                        <button
                        onClick={() => {
                            navigator.clipboard.writeText(product.COUPON.CODE);
                        }}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                        >
                            <i class="fa fa-copy" style={{ color: '#fff', fontSize: '12px' }}></i>
                            </button>
                            </span>
                            </>
                            : "" }
                            </div>
</div>

            {product.OFFERS && (
              <>
            <ProductOffers offers={product.OFFERS} />
              </>
            )}
           <a className="btn" href="#" onClick={(e) => {
        e.preventDefault(); 
        onReplyClick(); 
      }}>
        Reply
        </a>
            {/* {redirectLink ? (
            <LoadingRedirect link={redirectLink} />
          ) : ( */}
            <a className="btn shop_btn" href={product.LINK} target="_blank">
              Buy Now
            </a>
          {/* )} */}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-8 col-xl-9">
            <ProductDetails details={product.CONTENT} />
            <div class="shop single_set">
              {!loading && (
                <>
                 <h3>More Deals From {product.COUPON.NAME}</h3>
              <MoreDeals data={similarproducts}/>
              <DealHistory dealhistory={product.DEALHISTORY}/>
              <ProductComments id={product.ID}/>
                </>
              )}
             
            </div>
          </div>
          <div class="col-sm-4 col-xl-3">
            <DayDeals />
            {/* <Dealday/> */}
          </div>
        </div>
      </>
    );
}