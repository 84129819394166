import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useLocation } from 'react-router-dom';
const LoadingRedirect = () => {
  const location = useLocation();
  const { link } = location.state || {};
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); 

  useEffect(() => {
    // Set a delay before redirecting to the target URL
    const timer = setTimeout(() => {
      if (link) {
        window.location.href = link; // Redirect to the target URL
      }
    }, 2000); // 2-second delay

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [link]);

  return (
    <div>
      {isLoading ? (
        <div className="loading">Loading...</div> 
      ) : (
        <div>Redirecting...</div>
      )}
    </div>
  );
};

export default LoadingRedirect;

