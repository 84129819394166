import React, { useEffect, useState, forwardRef } from "react"
import { postComment, clearAction } from "../../../Reducer/commentSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Form = forwardRef(({ id }, ref)=>{
    const dispatch = useDispatch();
    const username = localStorage.getItem('comment_username');
  const email = localStorage.getItem('comment_email');

    const commentformRespoData = useSelector(
      (state) => state.postcomments.content
    );
    const commentaddsuccessData = useSelector(
      (state) => state.postcomments.addsuccess
    );
    useEffect(() => {
      if (commentformRespoData && commentaddsuccessData) {
        if (commentformRespoData.status == "success") {
            toast.success(commentformRespoData.responseData.message);
            dispatch(clearAction());
            setCommentForm({
              name: "",
              email: "",
              message: "",
            });
        }
        else {
            toast.error(commentformRespoData.responseData.message);
            dispatch(clearAction());
        }
      }
    }, [commentformRespoData, commentaddsuccessData]);
    const [commentform, setCommentForm] = useState({
        name: "",
        email: "",
        message: ""
    });
    const newcommentformSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData();
        localStorage.setItem('comment_username', commentform.name);
        localStorage.setItem('comment_email', commentform.email);
        formData.append("author", commentform.name);
        formData.append("email", commentform.email);
        formData.append("comment_post_ID", id);
        formData.append("comment", commentform.message);
          dispatch(postComment(formData));
          
    }
    const existcommentformSubmit = (event) => {
      event.preventDefault();
        let formData = new FormData();
        formData.append("author", username);
        formData.append("email", email);
        formData.append("comment_post_ID", id);
        formData.append("comment", commentform.message);
          dispatch(postComment(formData));
    }
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setCommentForm({
        ...commentform,
        [name]: value,
      });
    };
    return (
      <>
        <div class="col-12" ref={ref}>
          <form class="single_form">
            <textarea
              class="form-control"
              placeholder="Type Message"
              name="message"
              value={commentform.message}
              onChange={handleInputChange}
            ></textarea>
            {!username && !email && (
              <>
              <div class="inputdiv">
              <input
                type="text"
                placeholder="First Name"
                class="form-control"
                name="name"
                value={commentform.name}
                onChange={handleInputChange}
              />
              <input
                type="text"
                placeholder="Email Address"
                class="form-control"
                name="email"
                value={commentform.email}
                onChange={handleInputChange}
              />
            </div>
            <button class="btn" type="submit" onClick={newcommentformSubmit} style={{marginRight: '0'}}>
              Submit
            </button>
              </>
            ) }
            <button class="btn" type="submit" onClick={existcommentformSubmit} style={{marginRight: '0'}}>
              Submit
            </button>
           
          </form>
        </div>
      </>
    );
});

export default Form;